import { Component, inject, OnDestroy, Type } from '@angular/core';
import { AppConfig, SharedAppSettingsService } from '@luggagehero/shared/app-settings/data-access';
import { BookingStatus, PRICING_SERVICE, PricingService, SecuritySeal } from '@luggagehero/shared/interfaces';
import { SharedMomentService } from '@luggagehero/shared/services/moment';
import moment from 'moment';
import { filter, Subscription } from 'rxjs';

import { ModalService } from '../../../../../core/services/index';
import { BookingActionBaseComponent } from './booking-action.base-component';
import { BookingActionInfo } from './booking-action-info';
import { PickUpActionBaseComponent } from './pick-up-action.base-component';
import { ShowReceiptActionBaseComponent } from './show-receipt-action.base-component';

type UiStyle = 'primary' | 'success' | 'warning';

const PICKED_UP_STATUSES = ['CHECKED_OUT', 'PAID'];
const TICKER_MESSAGES = ['DO_NOT_TAKE_SCREENSHOT', 'LIVE_SCREEN_MUST_BE_PRESENTED_TO_STAFF'];

@Component({ template: '' })
export abstract class ValidateBookingActionBaseComponent extends BookingActionBaseComponent implements OnDestroy {
  protected modalService = inject(ModalService);
  private momentService = inject(SharedMomentService);
  private pricingService = inject<PricingService>(PRICING_SERVICE);
  private sharedAppSettingsService = inject<SharedAppSettingsService>(SharedAppSettingsService);

  public accessCode: string;
  private _isSelfService = false;

  private subscriptions: Subscription[];
  private _isCheckInSession = false;
  private _tickerMessages: string[];

  public get isCheckInSession(): boolean {
    return this._isCheckInSession;
  }
  public set isCheckInSession(value: boolean) {
    this._isCheckInSession = value;
    this.cd.markForCheck();
  }

  public get tickerMessages(): string[] {
    if (AppConfig.IS_NO_SCREENSHOT_TICKER_ENABLED && !this.isSelfService) {
      return this._tickerMessages;
    }
    return null;
  }

  public get action() {
    switch (this.booking?.status) {
      case 'CHECKED_IN':
        return this.pickUpActionInfo;

      case 'CHECKED_OUT':
      case 'PAID':
        return this.showReceiptActionInfo;

      default:
        return null;
    }
  }

  public get pickUpActionInfo(): BookingActionInfo {
    return new BookingActionInfo('PickUpFromDropOffConfirmation')
      .withAction({ componentToShow: this.pickUpActionComponentType })
      .withCallToAction(this._translate('PROCEED_TO_PICKUP'));
  }

  public get showReceiptActionInfo(): BookingActionInfo {
    let callToAction: string, title: string, subtitle: string;

    if (this.booking.paidDirectly) {
      const { total, currency } = this.latestOrder || this.booking.price.final;
      callToAction = this.pricingService.format(total, currency);
      title = 'PAY_IN_SHOP';
      subtitle = 'SHOW_THE_RECEIPT_AND_PAY_IN_SHOP';
    } else if (this.booking.paymentPostponedAt) {
      callToAction = 'GO_TO_PAYMENT';
      title = 'PAYMENT_FAILED';
      subtitle = null;
    } else {
      callToAction = 'VIEW_RECEIPT';
      title = 'PAYMENT_SUCCEEDED';
      subtitle = 'SHOW_THE_RECEIPT_TO_THE_STAFF';
    }

    return new BookingActionInfo('ShowReceiptFromPickupConfirmation')
      .withCallToAction(this._translate(callToAction))
      .withAction({ componentToShow: this.showReceiptActionComponentType })
      .withTitle(this._translate(title))
      .withSubtitle(subtitle && this._translate(subtitle))
      .withState(this.booking.paidDirectly || this.booking.paymentPostponedAt ? 'warning' : 'success');
  }

  public get isSelfService(): boolean {
    return this._isSelfService;
  }

  // checked in or confirmed always, checked out paid + 13 min max - put in app settings
  public get accessAllowed(): boolean {
    const alwaysAllowStatus: BookingStatus[] = ['CONFIRMED', 'CHECKED_IN'];
    const expiringStatus: BookingStatus[] = ['CHECKED_OUT', 'PAID'];
    if (alwaysAllowStatus.includes(this.booking.status)) {
      return true;
    }

    if (
      expiringStatus.includes(this.booking.status) &&
      moment().isBefore(
        moment(this.booking.period.checkOut).add(
          this.sharedAppSettingsService.current.maxTimeMinutesPinReuse,
          'minutes',
        ),
      )
    ) {
      return true;
    }

    return false;
  }

  public async getStorageAccessCode(): Promise<string | null> {
    if (!this.booking || !this.accessAllowed) {
      return null;
    }

    // don't always go to the service and create a new pin - especially after pickup
    return this.bookingService.getStorageAccessCode(this.booking._id);
  }

  public get hasLongStorageRoomAccessText(): boolean {
    return this.shop?.storageRoomAccessText?.length > 60 ? true : false;
  }

  public get pickupTime(): string {
    if (!PICKED_UP_STATUSES.includes(this.booking?.status)) {
      return null;
    }
    return this.momentService.formatDate(this.booking.period.checkOut, 'calendarTime', this.booking.timezone);
  }

  public get luggageImage(): string {
    if (!this.booking || !this.booking.luggage.smartImages || this.booking.luggage.smartImages.length === 0) {
      return null;
    }
    return this.booking.luggage.smartImages[0].name;
  }

  public get securitySeals(): SecuritySeal[] {
    if (!this.booking?.luggage?.smartImages || this.booking.luggage.smartImages.length === 0) {
      return null;
    }
    return this.booking.luggage.smartImages[0].securitySeals;
  }

  public get title(): string {
    let value: string;

    switch (this.booking?.status) {
      case 'CHECKED_IN':
        value = 'DROPOFF_CONFIRMED';
        break;

      case 'CHECKED_OUT':
      case 'PAID':
        value = 'PICKUP_CONFIRMED';
        break;

      case 'CANCELLED':
        value = 'BOOKING_CANCELLED';
        break;

      default:
        value = 'BOOKING_CONFIRMED';
        break;
    }

    return this._translate(value);
  }

  public get instructions(): string {
    let value: string;

    switch (this.booking?.status) {
      case 'CHECKED_IN': {
        value = this.isSelfService ? 'DEPOSIT_BAGS_AT' : 'SHOW_THIS_AND_HAND_OVER_BAGS_AT';
        break;
      }
      case 'CHECKED_OUT':
      case 'PAID': {
        value = this.booking.paidDirectly
          ? 'SHOW_THIS_AND_PAY_DIRECTLY_AT'
          : this.isSelfService
            ? 'COLLECT_BAGS_AT'
            : 'SHOW_THIS_TO_COLLECT_BAGS_AT';
        break;
      }
    }

    return value && this._translate(value);
  }

  public get statusIcon(): string {
    return this.style === 'warning' ? 'exclamation' : 'check';
  }

  public get style(): UiStyle {
    if (['CHECKED_OUT', 'PAID'].includes(this.booking?.status)) {
      return this.booking.paidDirectly ? 'warning' : 'success';
    }
    return 'primary';
  }

  public get maxTagsCollapsed(): number {
    return AppConfig.MAX_ITEMS_IN_COLLAPSED_TAG_LIST;
  }

  protected abstract get pickUpActionComponentType(): Type<PickUpActionBaseComponent>;
  protected abstract get showReceiptActionComponentType(): Type<ShowReceiptActionBaseComponent>;

  public async onBaseInit(): Promise<void> {
    await super.onBaseInit();

    this._tickerMessages = TICKER_MESSAGES.map((key) => this._translate(key));

    this.subscriptions = [];
    this.subscriptions.push(
      this.bookingService.bookingEvent$
        .pipe(filter((e) => e?.eventType === 'bagsDroppedOff'))
        .subscribe((_e) => (this.isCheckInSession = true)),
    );

    this._isSelfService =
      this.shop.storageRoomAccessCode || this.shop.storageRoomAccessText || this.shop.hasALock ? true : false;

    if (this._isSelfService) {
      this.accessCode = await this.getStorageAccessCode();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.subscriptions?.forEach((s) => s.unsubscribe());
  }
}
